<template>
  <div class="main-page">
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col-12 md:col-12 sm:col-12 comp-grid">
            <div>
              <form ref="observer" tag="form" @submit.prevent="validateForm()" @reset="resetForm">
                <div>
                  <table class="p-datatable-table" style="width: 100%">
                    <tbody>
                      <tr v-for="(formData, row) in arrFormData" :key="row" class="input-row">
                        <div class="card nice-shadow-5">
                          <div class="grid">
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("exams_type") }} *
                              </div>

                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass(row, 'exams_type')" optionLabel="label"
                                  optionValue="value" ref="ctrlexams_type" v-model="formData.exams_type"
                                  :options="$menus.shs_qualificationItems" :label="$t('exams_type')"
                                  :placeholder="$t('select')" @change="() => {
                                    !examsTypesThatRequiresGrades.includes(formData.exams_type) ? formData.grades = [] : formData.grades = [{ subject: '', grade: '' }]
                                  }
                                    ">
                                  <template #option="slotProps">
                                    <div class="flex align-items-center">
                                      <div>
                                        <div>
                                          {{ slotProps.option.label }}
                                        </div>
                                        <div v-if="slotProps.option.description" class="text-sm text-500">
                                          {{ slotProps.option.description }}
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("index_number") }} *
                              </div>

                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlindex_number" v-model.trim="formData.index_number"
                                  :label="$t('index_number')" type="text" :placeholder="$t('index_number')" class="w-full"
                                  :class="getErrorClass(row, 'index_number')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("year") }} *
                              </div>

                              <div class="">
                                <Dropdown class="w-full" ref="ctrlsitting_year" v-model="formData.sitting_year" :options="$utils.years(
                                  formData.sitting_year,
                                  null,
                                  dob_year
                                )
                                  " :label="$t('year')" :placeholder="$t('select')"
                                  :class="getErrorClass(row, 'sitting_year')">
                                </Dropdown>
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("month") }} *
                              </div>

                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown :class="getErrorClass(row, 'sitting_month')" class="w-full" optionLabel="label"
                                  optionValue="value" ref="ctrlsitting_month" v-model="formData.sitting_month"
                                  :options="$menus.exam_sitting_monthItems" :label="$t('month')"
                                  :placeholder="$t('select')">
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div v-if="formData.exams_type == other" class="col-12 md:col-12">
                              <div class="mt-3 mb-2 font-bold text-sm">
                                {{ $t("upload_gtec_evaluation_document") }}
                              </div>
                              <small class="text-xs text-primary">{{
                                $t("the_maximum_size_of_your_provided_national_id_must_not_be_more_than_10MB") }}
                                .pdf </small>
                              <br>
                              <small class="text-purple-500">{{ $t("if_your_gtec_evaluation_document") }}</small>
                              <div class="mt-2">
                                <!-- <div class="field mb-0"> -->
                                <Uploader :auto="true" :fileLimit="1" :maxFileSize="10000000" accept=".pdf"
                                  :multiple="false" style="width: 100%" :label="$t('choose_files_or_drop_files_here')"
                                  upload-path="s3uploader/upload/gtec_evaluation" v-model="formData.gtec_evaluation">
                                </Uploader>
                                <!-- </div> -->
                              </div>
                            </div>

                          </div>
                          <div
                            v-if="examsTypesThatRequiresGrades.includes(formData.exams_type) && iswaitingresults != yes"
                            class="grid card nice-shadow-5 mt-2">
                            <div class="col-12">
                              <div class="mb-3 text-purple-600 text-sm">
                                {{ $t("you_must_enter_your_grades_below") }}
                              </div>
                              <table class="p-datatable-table" style="width: 100%">
                                <tbody>
                                  <tr v-for="(item, key) in formData.grades" :key="key" class="input-row">
                                    <div class="mb-2">
                                      <div class="grid">
                                        <div class="col-12 md:col-6">
                                          <div class="mb-2 font-bold text-sm">
                                            {{ $t("subject") }} {{ key + 1 }} *
                                          </div>

                                          <div class="">
                                            <!-- <div class="field mb-0"> -->
                                            <Dropdown class="w-full" optionLabel="label" optionValue="value"
                                              ref="ctrlsubject" v-model="item.subject" :options="shsCourseList"
                                              :filter="true" :label="$t('subject')" :placeholder="$t('select')">
                                            </Dropdown>
                                            <!-- </div> -->
                                          </div>
                                        </div>
                                        <div class="col-12 md:col-6">
                                          <div class="mb-2 font-bold text-sm">
                                            {{ $t("grade") }} *
                                          </div>

                                          <div class="">
                                            <Dropdown class="w-full" optionLabel="label" optionValue="value"
                                              ref="ctrlgrade" v-model="item.grade"
                                              :options="formData.exams_type == 'wassce' ? wassce_gradesItems : other_gradesItems"
                                              :label="$t('grade')" :placeholder="$t('select')">
                                            </Dropdown>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <th>
                                      <Button class="p-button-text p-button-danger p-button-rounded p-button-sm"
                                        v-if="formData.grades.length > 1" @click="removeGradesFormRow(row, key)"
                                        icon="pi pi-times" />
                                    </th>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colspan="100" class="text-left">
                                      <div class="flex justify-content-center mb-3">
                                        <div class="">
                                          <Button @click="addGradesFormRow(row)" icon="pi pi-plus"
                                            :label="$t('add_subject')"
                                            class="p-button-text p-button-success p-button-sm p-0 mt-3" />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                        <th>
                          <Button class="p-button-text p-button-danger p-button-rounded p-button-sm"
                            v-if="arrFormData.length > minRowCount" @click="removeFormRow(row)" icon="pi pi-times" />
                        </th>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="100" class="text-left">
                          <div class="flex justify-content-center mb-3">
                            <div class="">
                              <Button v-if="arrFormData.length < maxRowCount" @click="addFormRow()" icon="pi pi-plus"
                                :label="$t('add_another_sitting')"
                                class="p-button-info p-button-outlined p-button-sm px-3 py-2 mt-3" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </form>
              <slot :submit="submit" :saving="saving"></slot>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { PageMixin } from "../../mixins/page.js";
import { AddPageMixin } from "../../mixins/addpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { ApiService } from "../../services/api";
import { helpers, required } from "@vuelidate/validators";
import { i18n } from "../../services/i18n.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "examsittingsPage",
  components: {},
  mixins: [PageMixin, AddPageMixin],
  props: {
    pageName: {
      type: String,
      default: "exam_sittings",
    },
    maxRowCount: {
      type: Number,
      default: 3,
    },
    minRowCount: {
      type: Number,
      default: 1,
    },
    sittings: { type: String, default: "[]" },
    iswaitingresults: { type: String, default: "" },
    dobYear: {
      type: Number,
      default: 1950,
    },
  },
  data() {
    return {
      arrFormData: [
        {
          sitting_year: "",
          sitting_month: "",
          exams_type: "",
          index_number: "",
          confirm_index_number: "",
          gtec_evaluation: "",
          verified_results: "",
          grades: [{ subject: "", grade: "" }],
        },
      ],
      examsTypesThatRequiresGrades: ["wassce","novdec","sssce"],
      other: "other",
      yes: "Yes",
      wassce_gradesItems: [
        { value: "A1", label: i18n.t('A1') },
        { value: "B2", label: i18n.t('B2') },
        { value: "B3", label: i18n.t('B3') },
        { value: "C4", label: i18n.t('C4') },
        { value: "C5", label: i18n.t('C5') },
        { value: "C6", label: i18n.t('C6') },
        { value: "D7", label: i18n.t('D7') },
        { value: "E8", label: i18n.t('E8') },
        { value: "F9", label: i18n.t('F9') },
        { value: "Withheld", label: i18n.t('Withheld') },
        { value: "Pending", label: i18n.t('Pending') },
        { value: "Absent", label: i18n.t('Absent') },
      ],
      other_gradesItems: [
        { value: "A", label: i18n.t('A') },
        { value: "B", label: i18n.t('B') },
        { value: "C", label: i18n.t('C') },
        { value: "D", label: i18n.t('D') },
        { value: "E", label: i18n.t('E') },
        { value: "F", label: i18n.t('F') },
      ],
    };
  },
  validations() {
    let arrFormData = {
      $each: helpers.forEach({
        sitting_year: { required },
        sitting_month: { required },
        exams_type: { required },
        index_number: { required },
        confirm_index_number: {},
        gtec_evaluation: {},
        verified_results: {},
        grades: {},
      }),
    };
    return { arrFormData };
  },
  computed: {
    ...mapGetters("exam_sittings", ["shsCourseList"]),
    pageTitle: {
      get: function () {
        return this.$t("add_new_exam_sittings");
      },
    },
  },
  methods: {
    ...mapActions("exam_sittings", [
      "saveRecord",
      "getShsCourses",
    ]),
    validateForm() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      return isFormValid;
    },
    getExamsSittings() {
      if (this.sittings) {
        let sittings = JSON.parse(this.sittings)
        sittings.forEach(function (data) {
          data.grades = JSON.parse(data.grades);
        });
        this.arrFormData = sittings;
      }
      if (this.arrFormData.length < 1) {
        this.addFormRow();
        this.addGradesFormRow(0);
      }
      this.dob_year = this.dobYear;
    },
    addFormRow() {
      let newFormData = {
        sitting_year: "",
        sitting_month: "",
        exams_type: "",
        index_number: "",
        confirm_index_number: "",
        gtec_evaluation: "",
        verified_results: "",
        grades: [],
      };
      this.arrFormData.push(newFormData);
    },
    removeFormRow(index) {
      this.arrFormData.splice(index, 1);
    },
    addGradesFormRow(index) {
      let newFormData = { subject: "", grade: "" };
      this.arrFormData[index].grades.push(newFormData);
    },
    removeGradesFormRow(row, index) {
      this.arrFormData[row].grades.splice(index, 1);
    },
    resetForm() {
      this.arrFormData = [
        {
          sitting_year: "",
          sitting_month: "",
          exams_type: "",
          index_number: "",
          confirm_index_number: "",
          gtec_evaluation: "",
          verified_results: "",
          grades: [{ subject: "", grade: "" }],
        },
      ];
      this.updateFormData();
    },
    getErrorClass(row, field) {
      let errors = this.v$.arrFormData.$each.$response.$errors[row][field];
      return { "p-invalid": errors.length && this.submitted };
    },
    lookupGrades(exams_type) {
      // console.log('____ ', exams_type)
      if (!this.examsTypesThatRequiresGrades.includes(exams_type)) {

      }
      // let url = `components_data/grade_scores_option_list?exams_type=${exams_type}`;
      // let data = { url };
      // this.getGradeScores(data);
    },
  },
  watch: {},
  async mounted() {
    this.getExamsSittings();
    this.getShsCourses();
  },
  async created() { },
};
</script>
<style scoped></style>