<template>
  <div class="main-page">
    <section class="page-section mb-3">
      <div class="container">
        <div class="card nice-shadow-16 bg-primary-100">
          <div class="flex justify-content-start align-items-center">
            <div class="mb-2">
              <Button icon="pi pi-arrow-left" class="p-button-sm p-button-rounded px-4" @click="() => {
                $router.go(-1);
              }
                " />
            </div>
            <div class="text-2xl text-primary font-bold mb-2 ml-3">
              {{ $t("application_form") }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col-12 md:col-12 sm:col-12 comp-grid">
            <div>
              <template v-if="!loading && item">
                <div v-if="item.application_status != this.completed && item.application_status != this.closed" class="grid">
                  <div class="col-12 sm:col-6 md:col-5 lg:col-4">
                    <div class="nice-shadow-18 sticky-item">
                      <!-- <Menu style="width: 100%" :model="items" /> -->
                      <ApplicationMenu :items="loadSteps(item.application_type)" class="layout-menu"
                        @menuitem-click="onMenuItemClick" @keydown="onKeyDown" />
                    </div>
                  </div>
                  <div class="col-12 sm:col-6 md:col-7 lg:col-8">
                    <!-- ---------- IS NOT POSTGRADUATE ---------- -->
                    <div v-if="item.application_type != this.postgraduate" class="card nice-shadow-18">
                      <div class="col-sm-4 col-md-4 col-12 comp-grid">
                        <div v-if="step_index == 0" class="">
                          <div class="reset-grid">
                            <applicationdata-applicants-page is-sub-page ref="applicantsEditPage" id=""
                              :show-header="true" v-if="ready" :pageIndex="0" @next="next">
                            </applicationdata-applicants-page>
                          </div>
                        </div>
                        <div v-if="step_index == 1" class="">
                          <div class="reset-grid">
                            <biodata-applicants-page is-sub-page ref="applicantsEditPage" id="" :show-header="true"
                              v-if="ready" :pageIndex="1" @next="next">
                            </biodata-applicants-page>
                          </div>
                        </div>
                        <div v-if="step_index == 2" class="">
                          <div class="reset-grid">
                            <familydata-applicants-page is-sub-page ref="applicantsEditPage" id="" :show-header="true"
                              v-if="ready" :pageIndex="2" @next="next">
                            </familydata-applicants-page>
                          </div>
                        </div>
                        <div v-if="step_index == 3" class="">
                          <div class="reset-grid">
                            <academicsdata-applicants-page is-sub-page ref="applicantsEditPage" id="" :show-header="true"
                              v-if="ready" :pageIndex="3" @next="next">
                            </academicsdata-applicants-page>
                          </div>
                        </div>
                        <div v-if="step_index == 4" class="">
                          <div class="reset-grid">
                            <programmesdata-applicants-page is-sub-page ref="applicantsEditPage" id="" :show-header="true"
                              v-if="ready" :pageIndex="4" @next="next">
                            </programmesdata-applicants-page>
                          </div>
                        </div>
                        <div v-if="step_index == 5" class="">
                          <div class="reset-grid">
                            <fundingdata-applicants-page is-sub-page ref="applicantsEditPage" id="" :show-header="true"
                              v-if="ready" :pageIndex="5" @next="next">
                            </fundingdata-applicants-page>
                          </div>
                        </div>
                        <div v-if="step_index == 6" class="">
                          <div class="reset-grid">
                            <payment-applicants-page is-sub-page ref="applicantsEditPage" id="" :show-header="true"
                              v-if="ready" :pageIndex="6" @next="next">
                            </payment-applicants-page>
                          </div>
                        </div>
                        <div v-if="step_index == 7" class="">
                          <div class="reset-grid">
                            <summarydata-applicants-page is-sub-page ref="applicantsEditPage" id="" :show-header="true"
                              v-if="ready" :pageIndex="7" @next="next">
                            </summarydata-applicants-page>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- ---------- IS POSTGRADUATE ---------- -->
                    <div v-else class="card nice-shadow-18">
                      <div v-if="step_index == 0" class="">
                        <div class="reset-grid">
                          <pg-applicationdata-applicants-page is-sub-page ref="applicantsEditPage" id=""
                            :show-header="true" v-if="ready" :pageIndex="0" @next="next">
                          </pg-applicationdata-applicants-page>
                        </div>
                      </div>
                      <div v-if="step_index == 1" class="">
                        <div class="reset-grid">
                          <pg-biodata-applicants-page is-sub-page ref="applicantsEditPage" id="" :show-header="true"
                            v-if="ready" :pageIndex="1" @next="next">
                          </pg-biodata-applicants-page>
                        </div>
                      </div>
                      <div v-if="step_index == 2" class="">
                        <div class="reset-grid">
                          <pg-workingexperience-applicants-page is-sub-page ref="applicantsEditPage" id=""
                            :show-header="true" v-if="ready" :pageIndex="2" @next="next">
                          </pg-workingexperience-applicants-page>
                        </div>
                      </div>
                      <div v-if="step_index == 3" class="">
                        <div class="reset-grid">
                          <pg-academicsdata-applicants-page is-sub-page ref="applicantsEditPage" id="" :show-header="true"
                            v-if="ready" :pageIndex="3" @next="next">
                          </pg-academicsdata-applicants-page>
                        </div>
                      </div>
                      <div v-if="step_index == 4" class="">
                        <div class="reset-grid">
                          <pg-fundingdata-applicants-page is-sub-page ref="applicantsEditPage" id="" :show-header="true"
                            v-if="ready" :pageIndex="4" @next="next">
                          </pg-fundingdata-applicants-page>
                        </div>
                      </div>
                      <div v-if="step_index == 5" class="">
                        <div class="reset-grid">
                          <payment-applicants-page is-sub-page ref="applicantsEditPage" id="" :show-header="true"
                            v-if="ready" :pageIndex="5" @next="next">
                          </payment-applicants-page>
                        </div>
                      </div>
                      <div v-if="step_index == 6" class="">
                        <div class="reset-grid">
                          <pg-summarydata-applicants-page is-sub-page ref="applicantsEditPage" id="" :show-header="true"
                            v-if="ready" :pageIndex="6" @next="next">
                          </pg-summarydata-applicants-page>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="grid">
                  <div class="col-12 md:col-12">
                    <div v-if="item.application_type != this.postgraduate" class="card nice-shadow-18">
                      <summarydata-applicants-page is-sub-page ref="applicantsEditPage" id="" :show-header="false"
                        v-if="ready" :pageIndex="7" :applicationCompleted="true" @next="next">
                      </summarydata-applicants-page>
                    </div>
                    <div v-else class="card nice-shadow-18">
                      <pg-summarydata-applicants-page is-sub-page ref="applicantsEditPage" id="" :show-header="false"
                        v-if="ready" :pageIndex="7" :applicationCompleted="true" @next="next">
                      </pg-summarydata-applicants-page>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="p-3 text-center">
                  <ProgressSpinner style="width: 50px; height: 50px" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import ApplicationMenu from "../../components/ApplicationMenu.vue";
import academicsdataApplicantsPage from "./academicsdata.vue";
import applicationdataApplicantsPage from "./applicationdata.vue";
import biodataApplicantsPage from "./biodata.vue";
import familydataApplicantsPage from "./familydata.vue";
import fundingdataApplicantsPage from "./fundingdata.vue";
import paymentApplicantsPage from "./payment.vue";
import programmesdataApplicantsPage from "./programmesdata.vue";
import summarydataApplicantsPage from "./summarydata.vue";

import pgAcademicsdataApplicantsPage from "./pg_academicsdata.vue";
import pgApplicationdataApplicantsPage from "./pg_applicationdata.vue";
import pgBiodataApplicantsPage from "./pg_biodata.vue";
import pgFundingdataApplicantsPage from "./pg_fundingdata.vue";
import pgSummarydataApplicantsPage from "./pg_summarydata.vue";
import pgWorkingexperienceApplicantsPage from "./pg_workingexperiencedata.vue";


import useVuelidate from "@vuelidate/core";
import { mapActions, mapGetters, mapState } from "vuex";
import { PageMixin } from "../../mixins/page.js";
import { ViewPageMixin } from "../../mixins/viewpage.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "editApplicantsPage",
  components: {
    ApplicationMenu,
    applicationdataApplicantsPage,
    biodataApplicantsPage,
    familydataApplicantsPage,
    academicsdataApplicantsPage,
    programmesdataApplicantsPage,
    fundingdataApplicantsPage,
    paymentApplicantsPage,
    summarydataApplicantsPage,
    pgApplicationdataApplicantsPage,
    pgBiodataApplicantsPage,
    pgAcademicsdataApplicantsPage,
    pgWorkingexperienceApplicantsPage,
    pgFundingdataApplicantsPage,
    pgSummarydataApplicantsPage,
  },
  mixins: [PageMixin, ViewPageMixin],
  props: {
    pageName: {
      type: String,
      default: "applicants",
    },
    idName: {
      type: String,
      default: "applicant_id",
    },
    routeName: {
      type: String,
      default: "applicantsedit",
    },
    pagePath: {
      type: String,
      default: "applicants/edit",
    },
    apiPath: {
      type: String,
      default: "applicants/edit",
    },
    pageIndex: { type: Number, default: 0 },
  },
  data() {
    return {
      item: {
        default: {},
      },
      submitted: false,
      step_index: 0,
      closed: "Closed",
      completed: "Completed",
      postgraduate: "Postgraduate",
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return this.$t("application_form");
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["applicants/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("applicants/setCurrentRecord", value);
      },
    },
  },
  methods: {
    ...mapActions("applicants", ["fetchRecord"]),
    onMenuItemClick(event) {
      this.$emit("appmenuitem-click", event);
    },
    onKeyDown(event) {
      const nodeElement = event.target;
      if (event.code === "Enter" || event.code === "Space") {
        nodeElement.click();
        event.preventDefault();
      }
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },
    nextPage(event) {
      for (let field in event.formData) {
        this.formObject[field] = event.formData[field];
      }

      this.$router.push(this.items[event.pageIndex + 1].to);
    },
    prevPage(event) {
      this.$router.push(this.items[event.pageIndex - 1].to);
    },
    loadSteps(application_type) {
      var steps = [];
      if (this.item.application_ref && application_type == this.postgraduate) {
        steps = [
          {
            label: this.$t("application_information"),
            icon: "pi pi-file mr-3",
            badge: this.item.country && this.learning_institution && this.academic_month,
            index: this.step_index,
            command: () => {
              this.step_index = 0; 
            },
          },
          {
            label: this.$t("bio_information"),
            icon: "pi pi-user-edit mr-3",
            badge: this.item.firstname,
            index: this.step_index,
            command: () => {
              this.step_index = 1;
            },
          },
          {
            label: this.$t("working_experience"),
            icon: "pi pi-briefcase mr-3",
            badge: this.item.has_working_experience,
            index: this.step_index,
            command: () => {
              this.step_index = 2;
            },
          },
          {
            label: this.$t("academic_history"),
            icon: "pi pi-book mr-3",
            badge: this.item.first_programme_id,
            index: this.step_index,
            command: () => {
              this.step_index = 3;
            },
          },
          {
            label: this.$t("funding_or_sponsorship"),
            icon: "pi pi-star mr-3",
            badge: this.item.sponsor,
            index: this.step_index,
            command: () => {
              this.step_index = 4;
            },
          },
          {
            label: this.$t("payment"),
            icon: "pi pi-wallet mr-3",
            badge: this.item.payment_status == "Paid",
            index: this.step_index,
            command: () => {
              this.step_index = 5;
            },
          },
          {
            label: this.$t("review_and_submit"),
            icon: "pi pi-send mr-3",
            badge: this.item.application_status == this.completed,
            index: this.step_index,
            command: () => {
              this.step_index = 6;
            },
          },
        ];
      } else {
        steps = [
          {
            label: this.$t("application_information"),
            icon: "pi pi-file mr-3",
            badge: this.item.country,
            index: this.step_index,
            command: () => {
              this.step_index = 0;
            },
          },
          {
            label: this.$t("bio_information"),
            icon: "pi pi-user-edit mr-3",
            badge: this.item.firstname,
            index: this.step_index,
            command: () => {
              this.step_index = 1;
            },
          },
          {
            label: this.$t("family_information"),
            icon: "pi pi-users mr-3",
            badge: this.item.fathers_name,
            index: this.step_index,
            command: () => {
              this.step_index = 2;
            },
          },
          {
            label: this.$t("academic_history"),
            icon: "pi pi-book mr-3",
            badge: this.item.attended_shs,
            index: this.step_index,
            command: () => {
              this.step_index = 3;
            },
          },
          {
            label: this.$t("programme_selections"),
            icon: "pi pi-th-large mr-3",
            badge: this.item.first_programme_id,
            index: this.step_index,
            command: () => {
              this.step_index = 4;
            },
          },
          {
            label: this.$t("funding_or_scholarship"),
            icon: "pi pi-star mr-3",
            badge: this.item.sponsor,
            index: this.step_index,
            command: () => {
              this.step_index = 5;
            },
          },
          {
            label: this.$t("payment"),
            icon: "pi pi-wallet mr-3",
            badge: this.item.payment_status == "Paid",
            index: this.step_index,
            command: () => {
              this.step_index = 6;
            },
          },
          {
            label: this.$t("review_and_submit"),
            icon: "pi pi-send mr-3",
            badge: this.item.application_status == this.completed,
            index: this.step_index,
            command: () => {
              this.step_index = 7;
            },
          },
        ];
      }
      return steps;
    },

    next(location) {
      this.step_index++;
      this.load();
      // window.scroll(10, 0);
    },
  },
  watch: {},
  async mounted() {
    if (this.pageIndex) {
      this.step_index = this.pageIndex;
    }
  },
  async created() { },
};
</script>
<style scoped></style>
