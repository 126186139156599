<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section">
        <div class="container">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div class="ml-2">
                    <div class="text-xl text-primary font-bold">
                      {{ $t("payment") }}
                    </div>
                    <Divider></Divider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col-12 md:col-12 sm:col-12 comp-grid">
            <div>
              <template v-if="!loading">
                <div v-if="formData.payment_status != 'Paid'" class="grid">
                  <div v-if="!transaction_id">
                    <div class="col-12">
                      <div class="text-purple-500">
                        <div class="font-bold text-yellow-500 mb-2">
                          {{ $t("instructions") }}
                        </div>
                        <ul>
                          <li>{{ $t("all_fields_marked_are_mandatory") }}</li>
                          <li v-if="currentAppConfig">
                            {{ $t("application_form_fee_is_only") }}:
                            <span class="font-bold"
                              >GHS
                              {{ currentAppConfig.application_form_fee }}</span
                            >
                          </li>
                          <li>
                            {{
                              $t(
                                "select_any_of_the_payment_methods_available_and_provide_required_information"
                              )
                            }}
                          </li>
                          <li>
                            {{
                              $t("telephone_wallet_number_must_be_ten_digit")
                            }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-12">
                      <form
                        ref="observer"
                        tag="form"
                        @submit.prevent="startRecordUpdate()"
                      >
                        <div class="card nice-shadow-5">
                          <div class="grid">
                            <div class="col-12 sm:col-12 lg:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("phone_number") }} *
                              </div>

                              <div class="grid">
                                <div class="col-12 md:col-5">
                                  <div class="">
                                    <!-- <div class="field mb-0"> -->
                                    <Dropdown
                                      class="w-full"
                                      :class="
                                        getErrorClass(
                                          'telephone_number_dial_code'
                                        )
                                      "
                                      optionLabel="dial_code"
                                      optionValue="value"
                                      ref="ctrltelephone_number_dial_code"
                                      :filter="true"
                                      :filterFields="['name', 'dial_code']"
                                      v-model="
                                        formData.telephone_number_dial_code
                                      "
                                      :options="$menus.dial_codes_list"
                                      :label="$t('dial_code')"
                                      :placeholder="$t('dial_code')"
                                    >
                                      <template #option="slotProps">
                                        <div class="flex align-items-center">
                                          <div>
                                            <div>
                                              {{ slotProps.option.name }}
                                            </div>
                                            <div
                                              v-if="slotProps.option.name"
                                              class="text-sm text-500"
                                            >
                                              {{ slotProps.option.code }} 
                                              {{ slotProps.option.dial_code }}
                                            </div>
                                          </div>
                                        </div>
                                      </template>
                                    </Dropdown>
                                    <!-- </div> -->
                                  </div>
                                </div>
                                <div class="col-12 md:col-7">
                                  <div class="">
                                    <!-- <div class="field mb-0"> -->
                                      <!-- pattern="^[1-9][0-9]{8,9,10}$" -->
                                    <InputText
                                      ref="ctrltelephone_number"
                                      v-model.trim="formData.telephone_number"
                                      :label="$t('phone_number')"
                                      type="text"
                                      :placeholder="$t('phone_number')"
                                      pattern="^[0-9]+$"
                                      class="w-full"
                                      maxlength="10"
                                      minlength="9"
                                      v-tooltip.top.focus="'Eg: 0209013836'"
                                      :class="getErrorClass('telephone_number')"
                                    >
                                    </InputText>
                                    <!-- </div> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 sm:col-12 lg:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("payment_method") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown
                                  class="w-full"
                                  :class="getErrorClass('payment_method')"
                                  optionLabel="label"
                                  optionValue="value"
                                  ref="ctrlpayment_method"
                                  v-model="formData.payment_method"
                                  :options="$menus.network_list"
                                  :label="$t('payment_method')"
                                  :placeholder="$t('select')"
                                  @change="validatePaymentMethod"
                                >
                                  <template #option="slotProps">
                                    <div class="flex align-items-center">
                                      <div
                                        class="ml-2"
                                        v-if="slotProps.option.image"
                                      >
                                        <img
                                          style="
                                            width: 42px;
                                            margin-right: 10px;
                                          "
                                          :src="slotProps.option.image"
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <div>{{ slotProps.option.label }}</div>
                                        <div
                                          v-if="slotProps.option.caption"
                                          class="text-sm text-500"
                                        >
                                          {{ slotProps.option.caption }}
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>

                            <div
                              v-if="formData.payment_method == card"
                              class="card nice-shadow-5 mx-2 mt-2"
                            >
                              <div class="grid">
                                <div class="col-12 md:col-7">
                                  <div class="mb-2 font-bold text-sm">
                                    {{ $t("card_holder_name") }} *
                                  </div>
                                  <div class="">
                                    <!-- <div class="field mb-0"> -->
                                    <InputText
                                      ref="ctrlcard_holder"
                                      v-model.trim="formData.card_holder"
                                      :label="$t('card_holder_name')"
                                      type="text"
                                      
                                      class="w-full"
                                      style="text-transform: uppercase"
                                      :class="getErrorClass('card_holder')"
                                    >
                                    </InputText>
                                    <!-- </div> -->
                                  </div>
                                </div>
                                <div class="col-12 md:col-5">
                                  <div class="mb-2 font-bold text-sm">
                                    {{ $t("card_number") }} *
                                  </div>
                                  <div class="">
                                    <!-- <div class="field mb-0"> -->
                                    <InputMask
                                      ref="ctrlcard_number"
                                      v-model.trim="formData.card_number"
                                      :label="$t('card_number')"
                                      type="text"
                                      placeholder="9999 9999 9999 9999"
                                      class="w-full"
                                      mask="9999 9999 9999 9999"
                                      :class="getErrorClass('card_number')"
                                    >
                                    </InputMask>
                                    <!-- </div> -->
                                  </div>
                                </div>

                                <div class="col-12 md:col-12">
                                  <div class="grid">
                                    <div class="col-12 md:col-4">
                                      <div class="mb-2 font-bold text-sm">
                                        {{ $t("expiry_month") }} *
                                      </div>
                                      <div class="">
                                        <!-- <div class="field mb-0"> -->
                                        <Dropdown
                                          class="w-full"
                                          :class="getErrorClass('expiry_month')"
                                          optionLabel="label"
                                          optionValue="value"
                                          ref="ctrlexpiry_month"
                                          v-model="formData.expiry_month"
                                          :options="$menus.expiry_monthItems"
                                          :label="$t('expiry_month')"
                                          :placeholder="$t('select')"
                                        ></Dropdown>
                                        <!-- </div> -->
                                      </div>
                                    </div>
                                    <div class="col-12 md:col-4">
                                      <div class="mb-2 font-bold text-sm">
                                        {{ $t("expiry_year") }} *
                                      </div>
                                      <div class="">
                                        <!-- <div class="field mb-0"> -->
                                        <Dropdown
                                          class="w-full"
                                          :class="getErrorClass('expiry_year')"
                                          ref="ctrlexpiry_year"
                                          optionLabel="label"
                                          optionValue="value"
                                          v-model="formData.expiry_year"
                                          :options="$menus.expiry_yearItems"
                                          :label="$t('expiry_year')"
                                          :placeholder="$t('select')"
                                        ></Dropdown>
                                        <!-- </div> -->
                                      </div>
                                    </div>
                                    <div class="col-12 md:col-4">
                                      <div class="mb-2 font-bold text-sm">
                                        {{ $t("cvv") }} *
                                      </div>
                                      <div class="">
                                        <!-- <div class="field mb-0"> -->
                                        <InputMask
                                          ref="ctrlcvv"
                                          v-model.trim="formData.cvv"
                                          :label="$t('cvv')"
                                          type="text"
                                          placeholder="999"
                                          pattern="^[0-9]+$"
                                          class="w-full"
                                          mask="999"
                                          :class="getErrorClass('cvv')"
                                        >
                                        </InputMask>
                                        <!-- </div> -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!--[form-content-end]-->
                        <div v-if="showSubmitButton" class="text-center my-3">
                          <Button
                            type="submit"
                            :label="$t('continue')"
                            icon="pi pi-send"
                            :loading="saving"
                          />
                        </div>
                      </form>
                      <slot :submit="submit" :saving="saving"></slot>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      transaction_id &&
                      transaction_message == 'WALLET' &&
                      transaction_code == '0'
                    "
                  >
                    <div class="col-12">
                      <div class="mb-3">
                        <i
                          style="font-size: 3em"
                          class="pi pi-wallet"
                        ></i>
                      </div>
                      <div class="">
                        <div class="font-bold mb-2">
                          {{ $t("payment_authorization_request_sent") }}
                        </div>
                        <div class="text-purple-500">
                          <ul>
                            <li>
                              {{
                                $t(
                                  "to_complete_payment_you_will_receive_a_prompt_on_your_mobile_device"
                                )
                              }}
                            </li>
                            <li>
                              {{
                                $t(
                                  "after_entering_your_pin_kindly_wait_for_a_confirmation_text"
                                )
                              }}
                            </li>
                          </ul>
                        </div>

                        <div class="mt-3">
                          <Button
                            :label="$t('i_have_paid')"
                            class="p-button-primary px-5"
                            :loading="saving"
                            @click="confirmPayment"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      transaction_code == '2' || transaction_code == '3'
                    "
                  >
                    <div class="col-12">
                      <div class="mb-3">
                        <i
                          style="font-size: 3em"
                          class="pi pi-credit-card"
                        ></i>
                      </div>
                      <div class="">
                        <div class="font-bold mb-2">
                          {{
                            $t(
                              "please_verify_first_time_use_of_card_with_amount_deducted_from_bank_account"
                            )
                          }}
                        </div>
                        <div class="text-purple-500 mb-2">
                          {{
                            $t(
                              "an_amount_of_money_has_been_deducted_from_your_bank_account"
                            )
                          }}
                        </div>
                        <div class="text-pink-400 text-sm">
                          {{
                            $t(
                              "card_will_be_blocked_after_3_failed_verification_attempts"
                            )
                          }}
                        </div>

                        <div class="card nice-shadow-5 mt-3">
                          <div class="grid">
                            <div class="col-12 md:col-12">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("amount") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText
                                  ref="ctrlcard_otp"
                                  v-model="card_otp"
                                  :label="$t('amount')"
                                  type="text"
                                  :placeholder="$t('amount')"
                                  pattern="^[0-9.]+$"
                                  class="w-full"
                                  maxlength="5"
                                >
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="mt-3 text-center">
                          <Button
                            :label="$t('verify')"
                            class="p-button-primary px-5"
                            :loading="saving"
                            @click="verifyCardOtp"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="grid">
                  <div class="col-12">
                    <div>
                      <Message
                        class="my-0"
                        icon="pi pi-wallet mr-4"
                        severity="success"
                        :closable="false"
                        >{{ $t("payment_was_successful") }}</Message
                      >
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="p-3 text-center">
                  <ProgressSpinner style="width: 50px; height: 50px" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { PageMixin } from "../../mixins/page.js";
import { EditPageMixin } from "../../mixins/editpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { i18n } from "../../services/i18n.js";
import { ApiService } from "../../services/api";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "paymentApplicantsPage",
  components: {},
  mixins: [PageMixin, EditPageMixin],
  props: {
    pageName: {
      type: String,
      default: "applicants",
    },
    idName: {
      type: String,
      default: "applicant_id",
    },
    routeName: {
      type: String,
      default: "applicantspayment",
    },
    pagePath: {
      type: String,
      default: "applicants/payment",
    },
    apiPath: {
      type: String,
      default: "applicants/payment",
    },
    pageIndex: { type: Number, default: 6 },
  },
  data() {
    return {
      formData: {
        telephone_number: "",
        telephone_number_dial_code: "233",
        payment_method: "",
        card_details: "",
        cvv: "",
        card_holder: "",
        card_number: "",
        expiry_month: "",
        expiry_year: "",
      },
      card_otp: "",
      transaction_id: null,
      transaction_code: "",
      transaction_message: "",
      transaction_msisdn: "",
      submitted: false,
      card: "CARD",
    };
  },
  computed: {
    ...mapGetters("app_config", ["currentAppConfig"]),
    pageTitle: {
      get: function () {
        return this.$t("edit_applicants");
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["applicants/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("applicants/setCurrentRecord", value);
      },
    },
  },
  validations() {
    let formData = {
      telephone_number_dial_code: { required },
      telephone_number: { required },
      payment_method: { required },
      cvv: {
        required: requiredIf(() => {
          const esit = this.formData.payment_method == this.card;
          return esit;
        }),
      },
      card_holder: {
        required: requiredIf(() => {
          const esit = this.formData.payment_method == this.card;
          return esit;
        }),
      },
      card_number: {
        required: requiredIf(() => {
          const esit = this.formData.payment_method == this.card;
          return esit;
        }),
      },
      expiry_month: {
        required: requiredIf(() => {
          const esit = this.formData.payment_method == this.card;
          return esit;
        }),
      },
      expiry_year: {
        required: requiredIf(() => {
          const esit = this.formData.payment_method == this.card;
          return esit;
        }),
      },
    };
    return { formData };
  },
  methods: {
    ...mapActions("applicants", ["updateRecord"]),
    async startRecordUpdate() {
      this.submitted = true;
      const isFormValid = await this.v$.$validate();
      if (!isFormValid) {
        this.flashMsg(
          this.$t("please_complete_the_form"),
          this.$t("form_is_invalid"),
          "error"
        );
        return;
      }
      this.saving = true;
      let id = this.id;
      let url = this.apiUrl;
      let form = this.normalizedFormData();

      let card_details = form.card_number
        ? form.card_number.replace(/\s/g, "") +
          "||" +
          this.$utils.upper(form.card_holder) +
          "||" +
          form.expiry_month +
          "||" +
          form.expiry_year +
          "||" +
          form.cvv
        : " ";

      let payload = {
        telephone_number:
          form.telephone_number_dial_code + form.telephone_number.replace(/^0+/, ''),
        payment_method: form.payment_method,
        card_details: Buffer.from(card_details).toString("base64"),
      };

      let data = { id, url, payload };
      this.updateRecord(data).then(
        (response) => {
          this.saving = false;
          let error_codes = ["1", "4", "5"];
          if (
            response.data.transaction_message == "CARD" &&
            response.data.transaction_code == "0"
          ) {
            this.$emit("next", this.pageIndex);
          } else if (error_codes.includes(response.data.transaction_code)) {
            this.transaction_id = null;
            this.flashMsg(
              this.$t(
                "payment_failed_auto_refund_initiated_please_check_funds"
              ),
              null,
              "error"
            );
          } else {
            this.transaction_id = response.data.transaction_id;
            this.transaction_code = response.data.transaction_code;
            this.transaction_message = response.data.transaction_message;
            this.transaction_msisdn = response.data.transaction_msisdn;
          }

          // this.resetForm();
          this.closeDialogs(); // close page dialog that if opened
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    async confirmPayment() {
      this.saving = true;
      let url = "payments/confirm";
      let payload = { transaction_id: this.transaction_id };
      ApiService.post(url, payload)
        .then((resp) => {
          this.saving = false;
          let record = resp.data;
          if (record.response_code && record.response_code == "0") {
            this.$emit("next", this.pageIndex);
          } else {
            this.flashMsg(
              this.$t("awaiting_payment_authorization_and_confirmation"),
              null,
              "warn"
            );
          }
        })
        .catch((err) => {
          this.saving = false;
          this.showPageRequestError(err);
        });
    },
    async verifyCardOtp() {
      this.saving = true;
      let url = "payments/verifyotp";
      let payload = {
        card_otp: this.card_otp,
        transaction_id: this.transaction_id,
        msisdn: this.transaction_msisdn,
      };

      ApiService.post(url, payload)
        .then((resp) => {
          this.saving = false;
          let record = resp.data;
          this.$emit("next", this.pageIndex);
        })
        .catch((err) => {
          this.saving = false;
          this.showPageRequestError(err);
        });
    },
    validatePaymentMethod() {
      if (this.formData.payment_method != this.card) {
        this.formData.card_holder = "";
        this.formData.card_details = "";
        this.formData.card_number = "";
        this.formData.expiry_month = "";
        this.formData.expiry_year = "";
        this.formData.cvv = "";
      }
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
    },
    resetForm() {
      //reset form fields value
      this.formData = {
        telephone_number: "",
        payment_method: "",
        card_details: "",
        cvv: "",
        card_number: "",
      };
      //raise event to reset other custom form components
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },
  },
  watch: {},
  async mounted() {},
  async created() {},
};
</script>
<style scoped>
li {
  margin-top: 0.5em;
  line-height: 1.4em;
}
</style>
