<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div class="ml-2">
                    <div class="text-xl text-primary font-bold">
                      {{ $t("academic_history") }}
                    </div>
                    <Divider></Divider>
                    <div class="text-purple-500">
                      <div class="font-bold text-yellow-500 mb-2">
                        {{ $t("instructions") }}
                      </div>
                      <ul>
                        <li>{{ $t("all_fields_marked_are_mandatory") }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col-12 md:col-12 sm:col-12 comp-grid">
            <div>
              <template v-if="!loading">
                <div class="grid">
                  <div class="col-12">
                    <form ref="observer" tag="form" @submit.prevent="startRecordUpdate()">
                      <!-- SHS Information -->
                      <div class="card nice-shadow-5">
                        <div class="mb-4 text-blue-500 font-bold text-md">
                          {{ $t("shs_information") }}
                        </div>
                        <!--[form-content-start]-->
                        <div class="grid">
                          <div class="col-12 md:col-7">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("did_you_attend_senior_high_school") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <Dropdown class="w-full" :class="getErrorClass('attended_shs')" ref="ctrlattended_shs"
                                optionLabel="label" optionValue="value" v-model="formData.attended_shs"
                                :options="$menus.yes_or_noItems" :label="$t('attended_shs')" :placeholder="$t('select')"
                                @change="validateHasAttendedShs">
                              </Dropdown>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div v-if="formData.attended_shs == yes" class="col-12 md:col-5">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("country_or_location") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <Dropdown class="w-full" :class="getErrorClass('shs_country')" ref="ctrlshs_country"
                                optionLabel="label" optionValue="value" v-model="formData.shs_country"
                                :options="$menus.shs_or_tertiary_countryItems" :label="$t('shs_country')"
                                :placeholder="$t('select')" @change="validateShsCountry">
                              </Dropdown>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div v-if="formData.attended_shs == yes &&
                            formData.shs_country != outsideGhana
                            " class="col-12 md:col-12">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("last_shs_attended") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <Dropdown class="w-full" :class="getErrorClass('last_shs_attended')" :filter="true"
                                optionLabel="label" optionValue="value" ref="ctrllast_shs_attended"
                                v-model="formData.last_shs_attended" :options="$menus.shs_list"
                                :label="$t('last_shs_attended')" :placeholder="$t('select')"
                                @change="validateLastShsAttended">
                                <template #option="slotProps">
                                  <div class="flex align-items-center">
                                    <div>
                                      <div>
                                        {{ slotProps.option.label }}
                                      </div>
                                      <div v-if="slotProps.option.region" class="text-sm text-500">
                                        {{ slotProps.option.region }} -
                                        <span class="text-purple-300">{{ $t("location") }}:</span>
                                        {{ slotProps.option.location }}
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </Dropdown>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div v-if="formData.attended_shs == yes &&
                            formData.shs_country == outsideGhana
                            " class="col-12 md:col-12">
                            <div class="mb-2 font-bold text-sm">
                              {{
                                $t("specify_senior_high_school_outside_ghana")
                              }}
                              *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <InputText ref="ctrllast_shs_outside" v-model.trim="formData.last_shs_outside" :label="$t('specify_senior_high_school_outside_ghana')
                                " type="text" :placeholder="$t('shs_outside_ghana')" pattern="^[^\d]+$"
                                class="w-full" :class="getErrorClass('last_shs_outside')">
                              </InputText>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div v-if="formData.last_shs_attended == other" class="col-12 md:col-12">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("last_shs_other") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <InputText ref="ctrllast_shs_other" v-model.trim="formData.last_shs_other"
                                :label="$t('specify_other')" type="text" :placeholder="$t('specify_other')"
                                pattern="^[^\d]+$" class="w-full" :class="getErrorClass('last_shs_other')">
                              </InputText>
                              <small class="text-xs text-primary mx-1">
                                {{ $t("last_shs_attended") }} ({{
                                  $t("specify_other")
                                }})</small>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div v-if="formData.attended_shs == yes" class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("from") }} *
                            </div>
                            <div class="">
                              <Dropdown class="w-full" :class="getErrorClass('shs_from_year')" ref="ctrlshs_from_year"
                                v-model="formData.shs_from_year" :options="$utils.years(
                                  formData.shs_from_year,
                                  null,
                                  formData.dob_year
                                )
                                  " :label="$t('shs_from_year')" :placeholder="$t('select')" @change="validateShsYear">
                              </Dropdown>
                            </div>
                          </div>
                          <div v-if="formData.attended_shs == yes" class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("to") }} *
                            </div>
                            <div class="">
                              <Dropdown class="w-full" :class="getErrorClass('shs_to_year')" ref="ctrlshs_to_year"
                                v-model="formData.shs_to_year" :options="$utils.years(
                                  formData.shs_to_year,
                                  null,
                                  formData.dob_year
                                )
                                  " :label="$t('shs_to_year')" :placeholder="$t('select')" @change="validateShsYear">
                              </Dropdown>
                            </div>
                          </div>
                          <div v-if="formData.attended_shs == yes" class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("shs_course") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <Dropdown class="w-full" :class="getErrorClass('shs_course')" optionLabel="label"
                                optionValue="value" :filter="true" ref="ctrlshs_course" v-model="formData.shs_course"
                                :options="$menus.shs_courseItems" :label="$t('shs_course')" :placeholder="$t('select')">
                              </Dropdown>
                              <!-- </div> -->
                            </div>
                          </div>
                          <!-- <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("shs_grade") }} *
                            </div>
                            <div class="">
                              <InputText
                                ref="ctrlshs_grade"
                                v-model.trim="formData.shs_grade"
                                :label="$t('shs_grade')"
                                type="text"
                                :placeholder="$t('shs_grade')"
                                pattern="[0-9]+$"
                                class="w-full"
                                :class="getErrorClass('shs_grade')"
                              >
                              </InputText>
                            </div>
                          </div> -->
                          <!-- <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("shs_certificate") }} *
                            </div>
                            <div class="">
                              <Uploader
                                :class="getErrorClass('certificate_url')"
                                :auto="true"
                                :fileLimit="1"
                                :maxFileSize="3000000"
                                accept=".jpg,.png,.pdf,.jpeg"
                                :multiple="false"
                                style="width: 100%"
                                :label="$t('choose_files_or_drop_files_here')"
                                upload-path="s3uploader/upload/certificate_url"
                                v-model="formData.certificate_url"
                              ></Uploader>
                            </div>
                          </div> -->
                        </div>
                      </div>

                      <!-- Exam Sitting -->
                      <div v-if="formData.attended_shs == yes" class="card nice-shadow-5">
                        <div class="mb-1 text-blue-500 font-bold text-md">
                          {{ $t("exams_sitting") }}
                        </div>
                        <small class="text-xs text-primary">{{
                          $t("you_can_add_up_to_4_exams_sittings")
                        }}</small>

                        <div class="mt-3">
                          <examsittings-page is-sub-page ref="exam_sittingsForm" :show-submit-button="false" v-if="ready"
                            :sittings="formData.exams_sittings" :iswaitingresults="formData.is_awaiting_results"
                            :dob-year="formData.dob_year"></examsittings-page>
                        </div>
                      </div>

                      <!-- Tertiary Information -->
                      <div class="card nice-shadow-5">
                        <div class="mb-4 text-blue-500 font-bold text-md">
                          {{ $t("tertiary_institution_information") }}
                        </div>
                        <!--[form-content-start]-->
                        <div class="grid">
                          <div class="col-12 md:col-7">
                            <div class="mb-2 font-bold text-sm">
                              {{
                                $t("have_you_attended_any_tertiary_institution")
                              }}
                              *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <Dropdown class="w-full" :class="getErrorClass('has_attended_tertiary')"
                                ref="ctrlhas_attended_tertiary" optionLabel="label" optionValue="value"
                                v-model="formData.has_attended_tertiary" :options="$menus.yes_or_noItems"
                                :label="$t('has_attended_tertiary')" :placeholder="$t('select')"
                                @change="validateHasAttendedTertiary">
                              </Dropdown>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div v-if="formData.has_attended_tertiary == yes" class="col-12 md:col-5">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("country_or_location") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <Dropdown class="w-full" :class="getErrorClass('tertiary_country')"
                                ref="ctrltertiary_country" optionLabel="label" optionValue="value"
                                v-model="formData.tertiary_country" :options="$menus.shs_or_tertiary_countryItems"
                                :label="$t('tertiary_country')" :placeholder="$t('select')">
                              </Dropdown>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div v-if="formData.has_attended_tertiary == yes &&
                            formData.tertiary_country != outsideGhana
                            " class="col-12 md:col-12">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("last_tertiary_attended") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <Dropdown class="w-full" :class="getErrorClass('last_tertiary_attended')"
                                ref="ctrllast_tertiary_attended" v-model="formData.last_tertiary_attended"
                                optionLabel="label" optionValue="value" :filter="true" :options="$menus.tertiary_list"
                                :label="$t('last_tertiary_attended')" :placeholder="$t('select')">
                              </Dropdown>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div v-if="formData.has_attended_tertiary == yes &&
                            formData.tertiary_country == outsideGhana
                            " class="col-12 md:col-12">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("specify_tertiary_outside_ghana") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <InputText ref="ctrltertiary_name_outside" v-model.trim="formData.tertiary_name_outside"
                                :label="$t('tertiary_outside_ghana')" type="text"
                                :placeholder="$t('tertiary_outside_ghana')" pattern="^[^\d]+$" class="w-full"
                                :class="getErrorClass('tertiary_name_outside')">
                              </InputText>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div v-if="formData.has_attended_tertiary == yes" class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("from") }} *
                            </div>
                            <div class="">
                              <Dropdown class="w-full" :class="getErrorClass('tertiary_from_year')"
                                ref="ctrltertiary_from_year" v-model="formData.tertiary_from_year" :options="$utils.years(
                                  formData.tertiary_from_year,
                                  null,
                                  formData.dob_year
                                )
                                  " :label="$t('tertiary_from_year')" :placeholder="$t('select')"
                                @change="validateTertiaryYear">
                              </Dropdown>
                            </div>
                          </div>
                          <div v-if="formData.has_attended_tertiary == yes" class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("to") }} *
                            </div>
                            <div class="">
                              <Dropdown class="w-full" :class="getErrorClass('tertiary_to_year')"
                                ref="ctrltertiary_to_year" v-model="formData.tertiary_to_year" :options="$utils.years(
                                  formData.tertiary_to_year,
                                  null,
                                  formData.dob_year
                                )
                                  " :label="$t('tertiary_to_year')" :placeholder="$t('select')"
                                @change="validateTertiaryYear">
                              </Dropdown>
                            </div>
                          </div>
                          <div v-if="formData.has_attended_tertiary == yes" class="col-12 md:col-12">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("tertiary_programme") }} *
                            </div>
                            <div class="">
                              <InputText ref="ctrltertiary_programme" v-model.trim="formData.tertiary_programme"
                                :label="$t('tertiary_programme')" type="text" :placeholder="$t('tertiary_programme')"
                                 class="w-full" :class="getErrorClass('tertiary_programme')">
                              </InputText>
                              <small class="text-xs text-primary mx-1">Eg. BSc. Information Technology</small>
                            </div>
                          </div>
                          <!-- <div
                            v-if="formData.has_attended_tertiary == yes"
                            class="col-12 md:col-6"
                          >
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("student_id_number") }} *
                            </div>
                            <div class="">
                              <InputText
                                ref="ctrltertiary_student_id"
                                v-model.trim="formData.tertiary_student_id"
                                :label="$t('student_id_number')"
                                type="text"
                                :placeholder="$t('student_id_number')"
                                pattern="^[A-Za-z0-9-]+$"
                                class="w-full"
                                :class="getErrorClass('tertiary_student_id')"
                              >
                              </InputText>
                            </div>
                          </div> -->
                          <div v-if="formData.has_attended_tertiary == yes" class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("tertiary_reason_for_leaving") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <Dropdown class="w-full" :class="getErrorClass('tertiary_reason_for_leaving')
                                " optionLabel="label" optionValue="value" ref="ctrltertiary_reason_for_leaving"
                                v-model="formData.tertiary_reason_for_leaving" :options="$menus.tertiary_reason_for_leavingItems
                                  " :label="$t('tertiary_reason_for_leaving')" :placeholder="$t('select')">
                              </Dropdown>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div v-if="formData.tertiary_reason_for_leaving == completed
                            " class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("degree_awarded") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <Dropdown class="w-full" :class="getErrorClass('tertiary_degree_awarded')
                                " optionLabel="label" optionValue="value" ref="ctrltertiary_degree_awarded"
                                v-model="formData.tertiary_degree_awarded" :options="$menus.tertiary_degree_list"
                                :label="$t('degree_awarded')" :placeholder="$t('select')">
                              </Dropdown>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div v-if="formData.tertiary_reason_for_leaving == completed
                            " class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("degree_class") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <Dropdown class="w-full" :class="getErrorClass('tertiary_degree_class')" optionLabel="label"
                                optionValue="value" ref="ctrltertiary_degree_class"
                                v-model="formData.tertiary_degree_class" :options="$menus.tertiary_degree_classItems"
                                :label="$t('degree_class')" :placeholder="$t('select')">
                              </Dropdown>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div v-if="formData.tertiary_reason_for_leaving == completed" class="col-12 md:col-12">
                            <div class="mt-3 mb-2 font-bold text-sm">
                              {{ $t("upload_transcript") }} *
                            </div>
                            <small class="text-xs text-primary">{{
                              $t("the_maximum_size_of_your_provided_national_id_must_not_be_more_than_10MB") }} .pdf .doc
                              .docx </small>
                            <br>
                            <small class="text-purple-500">{{ $t("please_upload_translation_of_transcript_if_not_english")
                            }}</small>
                            <div class="mt-2">
                              <Uploader :auto="true" :fileLimit="1" :class="getErrorClass('transcript')"
                                :maxFileSize="10000000" accept=".pdf" :multiple="false" style="width: 100%"
                                :label="$t('choose_files_or_drop_files_here')" upload-path="s3uploader/upload/transcript"
                                v-model="formData.transcript">
                              </Uploader>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Required Documents -->
                      <div class="card nice-shadow-5">
                        <div class="mb-4 text-pink-500 font-bold text-md">
                          {{ $t("Documents") }}
                        </div>

                        <div class="grid">
                          <div class="col-12 md:col-12">
                            <div class="mt-3 mb-2 font-bold text-sm">
                              {{ $t("attach_other_qualifications") }}
                            </div>
                            <div class="text-xs">
                              Document types: <b> WASSCE, SSSCE, ABCE, NABPTEX, GBCE, O/A LEVEL, HND, GTEC EVALUATION, etc. </b>
                            </div>
                            <small class="text-xs text-primary">{{
                              $t("the_maximum_size_of_your_provided_national_id_must_not_be_more_than_10MB") }} .pdf </small>
                            <br>
                            <small class="text-xs text-primary">Max files limit is 3. </small>
                            <div class="mt-2">
                              <Uploader :auto="true" :fileLimit="3" :class="getErrorClass('previous_qualifications')"
                                :maxFileSize="10000000" accept=".pdf" :multiple="true" style="width: 100%"
                                :label="$t('choose_files_or_drop_files_here')"
                                upload-path="s3uploader/upload/previous_qualifications"
                                v-model="formData.previous_qualifications">
                              </Uploader>
                            </div>
                          </div>
                        </div>

                        <div class="grid">
                          <div v-if="formData.application_type == postgradaute" class="col-12 md:col-6">
                            <div class="mt-3 mb-2 font-bold text-sm">
                              {{ $t("upload_recommendation_letters") }} *
                            </div>
                            <small class="text-xs text-primary">{{
                              $t("the_maximum_size_of_your_provided_national_id_must_not_be_more_than_10MB") }} .pdf .doc
                              .docx </small>
                            <br>
                            <div class="mt-2">
                              <Uploader :auto="true" :fileLimit="3" :class="getErrorClass('recommendation_letters')"
                                :maxFileSize="10000000" accept=".pdf" :multiple="true" style="width: 100%"
                                :label="$t('choose_files_or_drop_files_here')"
                                upload-path="s3uploader/upload/recommendation_letters"
                                v-model="formData.recommendation_letters">
                              </Uploader>
                            </div>
                          </div>

                          <div class="col-12 md:col-6" v-if="formData.is_foreign_student == yes">
                            <div class="mt-3 mb-2 font-bold text-sm">
                              {{ $t("English Proficiency Certificate") }} *
                            </div>
                            <small class="text-xs text-primary">{{
                              $t("the_maximum_size_of_your_provided_national_id_must_not_be_more_than_10MB") }} .pdf .doc
                              .docx </small>
                            <br>
                            <div class="mt-2">
                              <Uploader :auto="true" :fileLimit="3" :class="getErrorClass('english_proficiency')"
                                :maxFileSize="10000000" accept=".pdf" :multiple="true" style="width: 100%"
                                :label="$t('choose_files_or_drop_files_here')"
                                upload-path="s3uploader/upload/english_proficiency"
                                v-model="formData.english_proficiency">
                              </Uploader>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!--[form-content-end]-->
                      <div v-if="showSubmitButton" class="text-center my-3">
                        <Button type="submit" :label="$t('save_and_continue')" icon="pi pi-send" :loading="saving" />
                      </div>
                    </form>
                    <slot :submit="submit" :saving="saving"></slot>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="p-3 text-center">
                  <ProgressSpinner style="width: 50px; height: 50px" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import examsittingsPage from "../applicants/academicsdata_exam_sitting.vue";
import { PageMixin } from "../../mixins/page.js";
import { ApplicantEditPageMixin } from "../../mixins/applicanteditpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  requiredIf,
  email,
  sameAs,
  minLength,
  maxLength,
  minValue,
  maxValue,
  numeric,
  integer,
  decimal,
  ipAddress,
} from "@vuelidate/validators";
import { i18n } from "../../services/i18n.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "academicsdataApplicantsPage",
  components: { examsittingsPage },
  mixins: [PageMixin, ApplicantEditPageMixin],
  props: {
    pageName: {
      type: String,
      default: "applicants",
    },
    idName: {
      type: String,
      default: "applicant_id",
    },
    routeName: {
      type: String,
      default: "applicantsacademicsdata",
    },
    pagePath: {
      type: String,
      default: "applicants/academicsdata",
    },
    apiPath: {
      type: String,
      default: "applicants/academicsdata",
    },
    pageIndex: { type: Number, default: 3 },
  },
  data() {
    return {
      formData: {
        shs_exams_results: "",
        has_attended_tertiary: "",
        attended_shs: "",
        last_shs_attended: "",
        index_number: "",
        shs_country: "",
        shs_course: "",
        shs_grade: "",
        certificate_url: "",
        shs_from_year: "",
        shs_to_year: "",
        shs_qualification: "",
        last_tertiary_attended: "",
        tertiary_from_year: "",
        tertiary_to_year: "",
        tertiary_programme: "",
        tertiary_student_id: "",
        tertiary_reason_for_leaving: "",
        tertiary_degree_awarded: "",
        tertiary_degree_class: "",
        transcript: "",
        tertiary_country: "",
        tertiary_name_outside: "",
        last_shs_other: "",
        last_shs_outside: "",
        entry_level: "",
        entry_level_scenario: "",
        is_awaiting_results: this.na,
        exams_sittings: "",
        recommendation_letters: "",
        last_shs_attended_region: "",
        last_shs_attended_town: "",
        previous_qualifications: "",
        english_proficiency: "",
        dob_year: 1950,
      },
      yes: "Yes",
      completed: "Completed",
      no: "No",
      na: "N/A",
      other: "Other",
      inGhana: "In Ghana",
      outsideGhana: "Outside Ghana",
      postgradaute: "Postgradaute",
      submitted: false,
    };
  },
  computed: {
    ...mapGetters("applicants", ["academicDataRecord"]),
    pageTitle: {
      get: function () {
        return this.$t("edit_applicants");
      },
    },
    apiUrl: function () {
      if (this.id) {
        return this.apiPath + "/" + encodeURIComponent(this.id);
      }
      return this.apiPath;
    },
  },
  validations() {
    let formData = {
      has_attended_tertiary: { required },
      attended_shs: { required },
      last_shs_attended: {
        required: requiredIf(() => {
          const esit =
            this.formData.attended_shs == this.yes &&
            this.formData.shs_country == this.inGhana;
          return esit;
        }),
      },
      last_shs_other: {
        required: requiredIf(() => {
          const esit =
            this.formData.last_shs_attended == this.other &&
            this.formData.shs_country == this.inGhana;
          return esit;
        }),
      },
      index_number: {},
      shs_country: {
        required: requiredIf(() => {
          const esit = this.formData.attended_shs == this.yes;
          return esit;
        }),
      },
      last_shs_outside: {
        required: requiredIf(() => {
          const esit = this.formData.shs_country == this.outsideGhana;
          return esit;
        }),
      },
      shs_course: {
        required: requiredIf(() => {
          const esit = this.formData.attended_shs == this.yes;
          return esit;
        }),
      },
      shs_from_year: {
        required: requiredIf(() => {
          const esit = this.formData.attended_shs == this.yes;
          return esit;
        }),
      },
      shs_to_year: {
        required: requiredIf(() => {
          const esit = this.formData.attended_shs == this.yes;
          return esit;
        }),
      },
      shs_qualification: {},
      is_awaiting_results: {},
      last_tertiary_attended: {
        required: requiredIf(() => {
          const esit = this.formData.has_attended_tertiary == this.yes && this.formData.tertiary_country == this.inGhana;
          return esit;
        }),
      },
      tertiary_from_year: {
        required: requiredIf(() => {
          const esit = this.formData.has_attended_tertiary == this.yes;
          return esit;
        }),
      },
      tertiary_to_year: {
        required: requiredIf(() => {
          const esit = this.formData.has_attended_tertiary == this.yes;
          return esit;
        }),
      },
      tertiary_programme: {
        required: requiredIf(() => {
          const esit = this.formData.has_attended_tertiary == this.yes;
          return esit;
        }),
      },
      tertiary_reason_for_leaving: {
        required: requiredIf(() => {
          const esit = this.formData.has_attended_tertiary == this.yes;
          return esit;
        }),
      },
      tertiary_degree_awarded: {
        required: requiredIf(() => {
          const esit =
            this.formData.tertiary_reason_for_leaving == this.completed;
          return esit;
        }),
      },
      tertiary_degree_class: {
        required: requiredIf(() => {
          const esit =
            this.formData.tertiary_reason_for_leaving == this.completed;
          return esit;
        }),
      },
      transcript: {
        required: requiredIf(() => {
          const esit =
            this.formData.tertiary_reason_for_leaving == this.completed;
          return esit;
        }),
      },
      tertiary_country: {
        required: requiredIf(() => {
          const esit = this.formData.has_attended_tertiary == this.yes;
          return esit;
        }),
      },
      tertiary_name_outside: {
        required: requiredIf(() => {
          const esit = this.formData.tertiary_country == this.outsideGhana;
          return esit;
        }),
      },
      english_proficiency: {
        required: requiredIf(() => {
          const esit = this.formData.is_foreign_student == this.yes;
          return esit;
        }),
      },
      tertiary_student_id: {},
      previous_qualifications: {},
      shs_exams_results: {},
      recommendation_letters: {},
      certificate_url: {},
      shs_grade: {},
      entry_level: {},
      entry_level_scenario: {},
      exams_sittings: {},
    };
    return { formData };
  },
  methods: {
    ...mapActions("applicants", ["updateAcademicData", "fetchAcademicData"]),
    load: function () {
      var url = this.apiUrl;
      this.loading = true;
      this.ready = false; // hide other components until main page is ready
      this.fetchAcademicData(url).then(
        (response) => {
          this.loading = false;
          this.ready = true;
          // this.academicDataRecord = response.data;
          this.formData = response.data;
          this.updateFormFields();
        },
        (response) => {
          this.resetForm();
          this.loading = false;
          this.showPageRequestError(response);
        }
      );
    },
    async startRecordUpdate() {
      this.submitted = true;
      const isFormValid = await this.v$.$validate();
      let payload = this.normalizedFormData();
      payload.exam_sittings = this.$refs.exam_sittingsForm?.arrFormData;
      let errors = this.$refs.exam_sittingsForm?.validateForm();

      if (!isFormValid || !errors) {
        this.flashMsg(
          this.$t("please_complete_the_form"),
          this.$t("form_is_invalid"),
          "error"
        );
        return;
      }
      
      this.saving = true;
      let id = this.id;
      let url = this.apiUrl;
      let data = { id, url, payload };
      this.updateAcademicData(data).then(
        (response) => {
          this.saving = false;
          this.flashMsg(
            this.$t("information_was_updated"),
            this.$t("form_saved_successfully")
          );
          this.resetForm();
          this.closeDialogs(); // close page dialog that if opened
          this.$emit("next", this.pageIndex);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
    },
    validateShsYear() {
      const msg = "'To' year should be greater than 'From' year";
      if (this.formData.shs_from_year != "") {
        let from = parseFloat(this.formData.shs_from_year);
        let to = parseFloat(this.formData.shs_to_year);

        if (from > to) {
          this.showPageRequestError(msg);
          this.formData.shs_from_year = "";
          this.formData.shs_to_year = "";
        }
      }
    },
    validateTertiaryYear() {
      const msg = "'To' year should be greater than 'From' year";
      if (this.formData.tertiary_from_year != "") {
        let from = parseFloat(this.formData.tertiary_from_year);
        let to = parseFloat(this.formData.tertiary_to_year);

        if (from > to) {
          this.showPageRequestError(msg);
          this.formData.tertiary_from_year = "";
          this.formData.tertiary_to_year = "";
        }
      }
    },
    validateHasAttendedShs() {
      if (this.formData.attended_shs == this.no) {
        this.formData.last_shs_attended = "";
        this.formData.index_number = "";
        this.formData.shs_course = "";
        this.formData.shs_country = "";
        this.formData.shs_grade = "";
        this.formData.shs_from_year = "";
        this.formData.shs_to_year = "";
        this.formData.last_shs_other = "";
        this.formData.last_shs_outside = "";
        this.formData.shs_qualification = "";
        this.formData.is_awaiting_results = this.na;
      }
    },
    validateHasAttendedTertiary() {
      if (this.formData.has_attended_tertiary == this.no) {
        this.formData.last_tertiary_attended = "";
        this.formData.tertiary_from_year = "";
        this.formData.tertiary_to_year = "";
        this.formData.tertiary_programme = "";
        this.formData.tertiary_student_id = "";
        this.formData.tertiary_reason_for_leaving = "";
        this.formData.tertiary_degree_awarded = "";
        this.formData.tertiary_degree_class = "";
        this.formData.tertiary_country = "";
        this.formData.tertiary_name_outside = "";
      }
    },
    validateLastShsAttended() {
      if (this.formData.last_shs_attended != this.other) {
        this.formData.last_shs_other = "";
        var shs_record = this.$menus.shs_list.find(
          (e) => e.value == this.formData.last_shs_attended
        );
        this.formData.last_shs_attended_region = shs_record.region;
        this.formData.last_shs_attended_town = shs_record.location;
      }
    },
    validateShsCountry() {
      if (this.formData.shs_country == this.inGhana) {
        this.formData.last_shs_outside = "";
      } else {
        this.formData.last_shs_attended = "";
      }
    },
    resetForm() {
      //reset form fields value
      this.formData = {
        shs_exams_results: "",
        has_attended_tertiary: "",
        attended_shs: "",
        last_shs_attended: "",
        index_number: "",
        shs_course: "",
        shs_country: "",
        shs_grade: "",
        certificate_url: "",
        shs_from_year: "",
        shs_to_year: "",
        shs_qualification: "",
        last_tertiary_attended: "",
        tertiary_from_year: "",
        tertiary_to_year: "",
        tertiary_programme: "",
        tertiary_student_id: "",
        tertiary_reason_for_leaving: "",
        tertiary_degree_awarded: "",
        tertiary_degree_class: "",
        transcript: "",
        tertiary_country: "",
        tertiary_name_outside: "",
        last_shs_other: "",
        last_shs_outside: "",
        entry_level: "",
        entry_level_scenario: "",
        is_awaiting_results: this.na,
        exams_sittings: "",
        previous_qualifications: "",
        recommendation_letters: "",
      };
      // this.$refs.exam_sittingsForm.resetForm();
      // raise event to reset other custom form components
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },
  },
  watch: {},
  async mounted() {
    this.load();
  },
  async created() { },
};
</script>
<style scoped>
li {
  margin-top: 0.5em;
  line-height: 1.4em;
}
</style>
