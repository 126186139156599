<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div class="ml-2">
                    <div class="text-xl text-primary font-bold">
                      {{ $t("application_information") }}
                    </div>
                    <Divider></Divider>
                    <div class="text-purple-500">
                      <div class="font-bold text-yellow-500 mb-2">
                        {{ $t("instructions") }}
                      </div>
                      <ul>
                        <li>{{ $t("all_fields_marked_are_mandatory") }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col-12 md:col-12 sm:col-12 comp-grid">
            <div>
              <template v-if="!loading">
                <div class="grid">
                  <div class="col-12">
                    <form ref="observer" tag="form" @submit.prevent="startRecordUpdate()">
                      <div class="card nice-shadow-5">
                        <div class="mb-4 text-blue-500 font-bold text-md">
                          {{ $t("application_reference_number") }}:
                          {{ formData.application_ref }}
                        </div>

                        <div class="grid">
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("kindly_select_type_of_application") }}
                              *
                            </div>
                            <div class="">
                              <Dropdown class="w-full" :class="getErrorClass('application_type')" optionLabel="label"
                                optionValue="value" ref="ctrlapplication_type" v-model="formData.application_type"
                                :options="$menus.application_typeItems" :label="$t('application_type')"
                                :placeholder="$t('select')" @change="validateApplicationType" :disabled="true">
                              </Dropdown>
                            </div>
                          </div>
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("country_of_origin") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <Dropdown class="w-full" :class="getErrorClass('country')" optionLabel="label"
                                optionValue="value" :filter="true" ref="ctrlcountry" v-model="formData.country"
                                :disabled="disableCountry" :options="$menus.countries_list" :label="$t('country')"
                                :placeholder="$t('select')">
                              </Dropdown>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">{{ $t("academic_year") }} * </div>
                            <div id="ctrl-academic_year-holder">
                              <api-data-source :load-on-mount="true"
                                :api-path="`components_data/academic_year_options_list`">
                                <template v-slot="req">
                                  <Dropdown class="w-full" :class="getErrorClass('academic_year')"
                                    :loading="req.loading" optionLabel="label" optionValue="value"
                                    ref="ctrlacademic_year" v-model="formData.academic_year" :options="req.response"
                                    :label="$t('academic_year')" :placeholder="$t('select')" :disabled="true">
                                  </Dropdown>
                                </template>
                              </api-data-source>
                            </div>
                          </div>

                          <!-- <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">{{ $t("learning_institution") }} * </div>
                            <div id="ctrl-academic_month-holder">
                              <div class="">
                               
                                <Dropdown class="w-full" :class="getErrorClass('learning_institution')"
                                  optionLabel="label" optionValue="value" ref="ctrllearning_institution"
                                  v-model="formData.learning_institution" :options="$menus.learning_institutionItems"
                                  :label="$t('learning_institution')" :placeholder="$t('select')">
                                </Dropdown>
                                
                              </div>
                            </div>
                          </div> -->

                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">{{ $t("learning_institution") }} * </div>
                            <div id="ctrl-learning_institution-holder">
                              <api-data-source :load-on-mount="true"
                                :api-path="`components_data/learning_institution_options_list?lookup_id_academic_year=${formData.academic_year}`"
                                @loaded="formData.learning_institution">
                                <template v-slot="req">
                                  <Dropdown class="w-full" :class="getErrorClass('learning_institution')"
                                    :loading="req.loading" optionLabel="label" optionValue="value"
                                    ref="ctrllearning_institution" v-model="formData.learning_institution" :options="req.response"
                                    :label="$t('learning_institution')" :placeholder="$t('select')">
                                  </Dropdown>
                                </template>
                              </api-data-source>
                            </div>
                          </div>

                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">{{ $t("academic_entry_month") }} * </div>
                            <div id="ctrl-academic_month-holder">
                              <api-data-source :load-on-mount="true"
                                :api-path="`components_data/academic_month_options_list?lookup_id_academic_year=${formData.academic_year}&lookup_id_learning_institution=${formData.learning_institution}`"
                                @loaded="(response) =>{ if(response.length){
                                  formData.academic_month = response.map(item => item.value).includes(formData.academic_month) ? formData.academic_month : null
                                }}">
                                <template v-slot="req">
                                  <Dropdown class="w-full" :class="getErrorClass('academic_month')"
                                    :loading="req.loading" optionLabel="label" optionValue="value"
                                    ref="ctrlacademic_month" v-model="formData.academic_month" :options="req.response"
                                    :label="$t('academic_month')" :placeholder="$t('select')">
                                  </Dropdown>
                                </template>
                              </api-data-source>
                            </div>
                          </div>
                          <div v-if="formData.application_type == undergraduate" class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("awaiting_results") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <Dropdown class="w-full" :class="getErrorClass('is_awaiting_results')" optionLabel="label"
                                optionValue="value" ref="ctrlis_awaiting_results" v-model="formData.is_awaiting_results"
                                :options="$menus.yes_or_noItems" :label="$t('is_awaiting_results')"
                                :placeholder="$t('select')">
                              </Dropdown>
                              <!-- </div> -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Entry Information -->
                      <div v-if="formData.application_type != undergraduate" class="card nice-shadow-5">
                        <div class="mb-4 text-blue-500 font-bold text-md">
                          {{ $t("entry_information") }}
                        </div>

                        <div class="grid">
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("entry_level") }} *
                            </div>
                            <div class="">
                              <Dropdown class="w-full" :class="getErrorClass('entry_level')" optionLabel="label"
                                optionValue="value" ref="ctrlentry_level" v-model="formData.entry_level"
                                :options="$menus.entry_level_list" :label="$t('entry_level')"
                                :placeholder="$t('select')">
                              </Dropdown>
                            </div>
                          </div>
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("entry_level_scenario") }}
                            </div>
                            <div class="">
                              <Dropdown class="w-full" :class="getErrorClass('entry_level_scenario')"
                                optionLabel="label" optionValue="value" ref="ctrlentry_level_scenario"
                                v-model="formData.entry_level_scenario" :options="$menus.entry_scenario_list"
                                :label="$t('entry_level_scenario')" :placeholder="$t('select')">
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!--[form-content-end]-->
                      <div v-if="showSubmitButton" class="text-center my-3">
                        <Button type="submit" :label="$t('save_and_continue')" icon="pi pi-send" :loading="saving" />
                      </div>
                    </form>
                    <slot :submit="submit" :saving="saving"></slot>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="p-3 text-center">
                  <ProgressSpinner style="width: 50px; height: 50px" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { mapActions, mapGetters, mapState } from "vuex";
import { ApplicantEditPageMixin } from "../../mixins/applicanteditpage.js";
import { PageMixin } from "../../mixins/page.js";
import { i18n } from "../../services/i18n.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "applicationdataApplicantsPage",
  components: {},
  mixins: [PageMixin, ApplicantEditPageMixin],
  props: {
    pageName: {
      type: String,
      default: "applicants",
    },
    idName: {
      type: String,
      default: "applicant_id",
    },
    routeName: {
      type: String,
      default: "applicantsapplicationdata",
    },
    pagePath: {
      type: String,
      default: "applicants/applicationdata",
    },
    apiPath: {
      type: String,
      default: "applicants/applicationdata",
    },
    pageIndex: { type: Number, default: 0 },
  },
  data() {
    return {
      formData: {
        application_type: "",
        country: "",
        is_awaiting_results: "",
        learning_institution: "",
        entry_level: "",
        entry_level_scenario: "",
        academic_year: "",
        academic_month: "",
      },
      undergraduate: "Undergraduate",
      postgraduate: "Postgraduate",
      na: "N/A",
      submitted: false,
      disableCountry: false,
    };
  },
  computed: {
    ...mapGetters("applicants", ["applicationDataRecord"]),
    pageTitle: {
      get: function () {
        return this.$t("edit_applicants");
      },
    },
    apiUrl: function () {
      if (this.id) {
        return this.apiPath + "/" + encodeURIComponent(this.id);
      }
      return this.apiPath;
    },
  },
  validations() {
    let formData = {
      application_type: { required },
      academic_year: { required },
      academic_month: { required },
      country: { required },
      learning_institution: { required },
      is_awaiting_results: {
        required: requiredIf(() => {
          const esit = this.formData.application_type == this.undergraduate;
          return esit;
        }),
      },
      entry_level: {
        required: requiredIf(() => {
          const esit = this.formData.application_type != this.undergraduate;
          return esit;
        }),
      },
      entry_level_scenario: {},
    };
    return { formData };
  },
  methods: {
    ...mapActions("applicants", [
      "updateApplicationData",
      "fetchApplicationData",
    ]),
    load: function () {
      var url = this.apiUrl;
      this.loading = true;
      this.ready = false; // hide other components until main page is ready
      this.fetchApplicationData(url).then(
        (response) => {
          this.loading = false;
          this.ready = true;
          // this.fundingDataRecord = response.data;
          this.formData = response.data;
          this.updateFormFields();
        },
        (response) => {
          this.resetForm();
          this.loading = false;
          this.showPageRequestError(response);
        }
      );
    },
    async startRecordUpdate() {
      this.submitted = true;
      const isFormValid = await this.v$.$validate();
      if (!isFormValid) {
        this.flashMsg(
          this.$t("please_complete_the_form"),
          this.$t("form_is_invalid"),
          "error"
        );
        return;
      }
      this.saving = true;
      let id = this.id;
      let url = this.apiUrl;
      let payload = this.normalizedFormData();
      let data = { id, url, payload };
      this.updateApplicationData(data).then(
        (response) => {
          this.saving = false;
          this.flashMsg(
            this.$t("information_was_updated"),
            this.$t("form_saved_successfully")
          );
          this.resetForm();
          this.closeDialogs(); // close page dialog that if opened
          window.location.reload();
          this.$emit("next", this.pageIndex);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
      this.checkFormData()
    },
    checkFormData() {
      if (this.formData.country) {
        this.disableCountry = true
      }
    },
    resetForm() {
      //reset form fields value
      this.formData = {
        application_type: "",
        country: "",
        is_awaiting_results: "",
      };
      //raise event to reset other custom form components
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },
    validateApplicationType() {
      if (this.formData.application_type != this.undergraduate) {
        this.formData.is_awaiting_results = this.na;
      }
    },
    confirmSubmission() {
      this.$confirm.require({
        message: this.$t("This Application Information form is an integral part of your application process. You will NOT be able to make some changes after saving. Are you sure you want to save now?"),
        header: this.$t("confirmation"),
        icon: "pi pi-info-circle",
        accept: () => {
          this.startRecordUpdate();
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
  },
  watch: {},
  async mounted() {
    this.load();
  },
  async created() { },
};
</script>
<style scoped>
li {
  margin-top: 0.5em;
  line-height: 1.4em;
}
</style>
